APP.init = function () {
	APP.device();

	// SmoothScroll({
	// 	animationTime: 400,
	// 	accelerationDelta: 20,
	// 	accelerationMax: 1,
	// });

	// $('[data-modal]').on('click', function (e) {
	// 	e.preventDefault();
	// 	const obj = $(this);
	// 	const modalShow = obj.data('modal');
	// 	if (modalShow) APP.modal(modalShow);
	// });

	$('select').select2({
		minimumResultsForSearch: -1,
		width: 'resolve',
		templateSelection: function (data, container) {
			if (data.id !== '') {
				$(container).addClass('select2-selection__rendered--value');
			} else {
				$(container).removeClass('select2-selection__rendered--value');
			}
			return data.text;
		},
	});

	// $('.collapse').each(function () {
	// 	APP.collapse(this);
	// });

	// APP.taber('.taber');

	APP.agreeCookie();
	APP.header();
	APP.footer();
	APP.navigation();
	APP.bannerHero();
	APP.buildYourOwn();
	APP.waypoint();
	APP.carSelector();
	APP.ctaContact();
	// APP.form();
	// APP.standardHero();
	// APP.specification();
	// APP.sideNavigation();
	// APP.carCompare();
	// APP.tableTabbed();
	// APP.aboutUs();
	// APP.dealerNetwork();
	// APP.testDrive();
	// APP.registerDealer();
	// APP.recallChecking();
	// APP.bookAService();
	// APP.costEstimate();
	// APP.contactUs();
	// APP.serviceInterval();
	// APP.carouselImage();
	// APP.carouselTabbed();
	// APP.heritage();
	// APP.carCalculator();
	// APP.fleetsale();
	// APP.xpander();
	// APP.career();
	// APP.configurator();
	// APP.configurator3d();
	// APP.registerOne();
	// APP.registerTwo();
	// APP.qrCode();
};
