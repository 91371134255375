APP.agreeCookie = function () {
	const el = $('.agree-cookie');
	const btnAgree = el.find('.btn');

	function init() {
		if (el.length === 0) return;

		checkAgreeCookie();

		btnAgree.on('click', handleAgreeCookie);
	}

	function handleAgreeCookie(e) {
		e.preventDefault();
		localStorage.setItem('agree-cookie', true);
		checkAgreeCookie();
	}

	function checkAgreeCookie() {
		const agreeCookie = localStorage.getItem('agree-cookie')
			? localStorage.getItem('agree-cookie')
			: null;

		if (agreeCookie) {
			el.hide();
		} else {
			el.show();
		}
	}

	init();
};
