APP.carSelector = function () {
	const slickCarSelector = $('.slick--car-selector');
	const slickCarItemLength = $('.slick--car-selector .slick__item').length;
	const isMinLength = slickCarItemLength > 3;

	const init = function () {
		slickCarSelector.slick({
			infinite: true,
			centerMode: isMinLength,
			centerPadding: '180px',
			slidesToShow: isMinLength ? 3 : 4,
			slidesToScroll: isMinLength ? 3 : 4,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						infinite: true,
						centerMode: true,
						centerPadding: '60px',
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});
	};

	init();
};
