APP.buildYourOwn = function () {
	const el = $('.build-your-own');
	const buildYourOwnSelect = el.find('.build-your-own__select');
	const modelCarItem = el.find('.model-car__item');
	const productCarItem = el.find('.product-car__item');

	function init() {
		if (el.length === 0) return;

		gsap.set($('.background-car__item').eq(0), {
			x: '-30%',
			zIndex: 1,
		});

		modelCarItem.each(function () {
			const obj = $(this);
			const slickProduct = obj.find('.slick--product');
			const colorCarItem = obj.find('.color-car__item');
			const backgroundCarItem = obj.find('.background-car__item');
			const productCarItemActive = obj.find('.product-car__item');
			const colorTextCarItem = obj.find('.color-text-car__item');

			const dataColor = $('.color-car__item').eq(0).data('class-name');
			$('.model-car__item').eq(0).addClass(dataColor);

			colorCarItem.on('click', function (e) {
				e.preventDefault();
				const obj = $(this);
				const index = obj.index();
				const dataColor = obj.data('class-name');
				const parent = obj.parents('.model-car__item');

				modelCarItem.removeClass('is-white is-black');
				parent.addClass(dataColor);

				if (index === 0) {
					productCarItemActive.eq(0).addClass(CLASS._animate);
				} else {
					productCarItemActive.removeClass(CLASS._animate);
				}

				slickProduct.slick('slickGoTo', 0);
				colorCarItem.removeClass(CLASS._active);
				obj.addClass(CLASS._active);

				gsap.set(backgroundCarItem, {
					zIndex: 0,
				});

				gsap.set(backgroundCarItem.eq(index), {
					zIndex: 1,
					x: '100%',
				});

				gsap.to(backgroundCarItem.eq(index), {
					duration: 0.6,
					x: '-30%',
					ease: 'power1',
					onComplete: function () {
						gsap.set(backgroundCarItem, {
							zIndex: 0,
							x: '100%',
						});

						gsap.set(backgroundCarItem.eq(index), {
							zIndex: 1,
							x: '-30%',
						});
					},
				});

				gsap.set(colorTextCarItem, {
					display: 'none',
				});

				gsap.set(colorTextCarItem.eq(index), {
					display: 'block',
				});

				productCarItemActive.css({
					opacity: 0,
					zIndex: 0,
				});
				productCarItemActive.eq(index).css({
					opacity: 1,
					zIndex: 1,
				});
			});
		});

		productCarItem.each(function () {
			const obj = $(this);
			const slickProduct = obj.find('.slick--product');
			const slickControls = obj.find('.product-car__controls');
			const slickPaging = obj.find('.product-car__paging');
			const slickPrev = obj.find('.product-car__arrow--prev');
			const slickNext = obj.find('.product-car__arrow--next');

			slickPrev.on('click', function () {
				slickProduct.slick('slickPrev');
			});

			slickNext.on('click', function () {
				slickProduct.slick('slickNext');
			});

			slickProduct.on('init reInit afterChange', function (event, slick, currentSlide) {
				let i = (currentSlide ? currentSlide : 0) + 1;
				let slideCount = slick.slideCount;
				let number = i > 9 ? i : '0' + i;

				if (slideCount === 1) {
					slickControls.hide();
				}

				let total = slideCount > 9 ? slideCount : '0' + slideCount;

				slickPaging.html(number + '<span>/</span>' + total);
			});

			slickProduct.slick({
				arrows: false,
				infinite: true,
				fade: true,
				speed: 300,
			});
		});

		buildYourOwnSelect.on('change', function (e) {
			e.preventDefault();
			const obj = $(this);
			const value = obj.val();
			const modelCarItemActive = $(value);
			const colorCarItemFirst = modelCarItemActive.find('.color-car__item').eq(0);
			const productCarItemFirst = modelCarItemActive.find('.product-car__item').eq(0);

			modelCarItem.css({
				opacity: 0,
				zIndex: 0,
			});

			modelCarItemActive.css({
				opacity: 1,
				zIndex: 1,
			});

			productCarItem.removeClass(CLASS._animate);
			productCarItemFirst.addClass(CLASS._animate);
			colorCarItemFirst.trigger('click');
		});

		el.waypoint(
			function (direction) {
				if (direction === 'down') {
					$('.model-car__item').eq(0).find('.product-car__item').addClass(CLASS._animate);
				}
			},
			{
				offset: '75%',
			}
		);
	}

	init();
};
