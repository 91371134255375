APP.bannerHero = function () {
	const bannerHero = $('.banner-hero');
	const slickBannerHero = bannerHero.find('.slick--banner-hero');

	const init = function () {
		slickBannerHero.slick({
			infinite: true,
			arrows: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
					},
				},
			],
		});
	};

	init();
};
