APP.waypoint = function () {
	$('.is-skew-right').waypoint(
		function (direction) {
			if (direction === 'down') {
				$(this.element).addClass(CLASS._animate);
			} else {
				// $(this.element).removeClass(CLASS._animate);
			}
		},
		{
			offset: '75%',
		}
	);

	$('.is-skew-left').waypoint(
		function (direction) {
			if (direction === 'down') {
				$(this.element).addClass(CLASS._animate);
			} else {
				// $(this.element).removeClass(CLASS._animate);
			}
		},
		{
			offset: '75%',
		}
	);
};
