APP.footer = function () {
	$('.nav-foot__title').on('click', function (e) {
		e.preventDefault();
		const obj = $(this);
		const parent = obj.parent();

		if (parent.hasClass(CLASS._show)) {
			parent.removeClass(CLASS._show);
		} else {
			$('.nav-foot').removeClass(CLASS._show);
			parent.addClass(CLASS._show);
		}
	});
};
