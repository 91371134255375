APP.device = function () {
	const init = function () {
		detect();
		APP.resize(detect);
	};

	const detect = function () {
		BODY.removeClass(CLASS._mobile)
			.removeClass(CLASS._tablet)
			.removeClass(CLASS._desktop)
			.removeClass(CLASS._portrait)
			.removeClass(CLASS._landscape);

		if (APP.isLandscape()) {
			BODY.addClass(CLASS._landscape);
		} else {
			BODY.addClass(CLASS._portrait);
		}

		if (APP.isMobile()) {
			BODY.addClass(CLASS._mobile);
		} else if (APP.isTablet()) {
			BODY.addClass(CLASS._tablet);
		} else {
			BODY.addClass(CLASS._desktop);
		}
	};

	init();
};
